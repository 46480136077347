var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "operation-platform-container" },
    [
      _c("top-wrap", {
        attrs: {
          total: _vm.page.total,
          filterIndex: _vm.filterIndex,
          "filter-store": _vm.filterStore,
          "header-filter": _vm.headerFilter,
          "default-month": _vm.defaultMonth,
          "charts-data": _vm.chartsData,
          "loading-charts": _vm.loadingCharts,
        },
        on: {
          "top-data-change-cb": _vm.topDataChange,
          "clean-head-filter": _vm.handelCleanHeadFilter,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ chartsData, loadingCharts }) {
              return [
                _c("operation-data-charts", {
                  attrs: {
                    "charts-data": chartsData,
                    "loading-charts": loadingCharts,
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("operation-data-table", {
        attrs: {
          "table-data": _vm.tableData,
          "table-data-loading": _vm.tableDataLoading,
        },
      }),
      _c("table-page-tab", {
        attrs: {
          filterIndex: _vm.filterIndex,
          pageTabs: _vm.pageTabJson,
          "in-tab": false,
        },
      }),
      _c(
        "div",
        { staticClass: "work-table-footer" },
        [
          _c(
            "el-pagination",
            {
              staticClass: "el-pagination-workTable",
              attrs: {
                "current-page": _vm.page.current + 1,
                "page-sizes": [20, 30, 50],
                "page-size": _vm.page.size,
                layout: "total, sizes, prev, pager, next, slot",
                total: _vm.page.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("前往"),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("页"),
                  ],
                  1
                ),
                _c("span", { on: { click: _vm.handlePagerJump } }, [
                  _vm._v("跳转"),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }