<template>
    <div class="charts-container" v-loading="loadingCharts">
        <div class="chart-cell">
            <div class="chart-title">认领数据</div>
            <div class="chart"></div>
        </div>

        <div class="chart-cell">
            <div class="chart-title">推荐数据</div>
            <div class="chart"></div>
        </div>

        <div class="chart-cell">
            <div class="chart-title">面试数据</div>
            <div class="chart"></div>
        </div>

        <div class="chart-cell">
            <div class="chart-title">多推数据</div>
            <div class="chart"></div>
        </div>
    </div>
</template>

<script>
import echarts from 'echarts';
import tool from '#/js/tool.js';

export default {
    name: 'operation-data-charts',
	components: {
	},
    props: {
        chartsData: {
            type: Object,
            default: () => {},
        },
        loadingCharts: {
            type: Boolean,
            default: false,
        }
    },
	data() {
		return {
        };
    },
    created() {
    },
	mounted() {
		// this.renderChart();
        // window.onresize = tool.throttle(() => {
        //     this.renderChart();
        // }, 1000)
	},
    watch: {
        chartsData: {
            handler(newVal, oldVal) {
                this.renderChart();
            },
            deep: true,
        }
    },
	methods: {
        renderChart() {
            // let myChartA = echarts.init(document.getElementById('chartsDOM-A'));

            // var datas = [
            //     [
            //         { name: '认领', value: 2},
            //         { name: '未认领', value: 37 },
            //     ],
            // ];
            var datas =[
                    { name: '认领', value: 2},
                    { name: '未认领', value: 37 },
                ];
            const option = {
                color: ["#1C938C", "#38BC9D", "#B4C5E8", "#e4cebf", "#FC8452"],
                title: {
                    text: '{subtitle|人才总数}\n {count|5000}',
                    left: 'center',
                    top: 'middle',
                    textStyle: {
                        color: '#333',
                        fontWeight: 'normal',
                        fontSize: 14,
                        textAlign: 'center',
                        rich: {
                            subtitle: {
                                fontSize: 15,
                                color: '#666'
                            },
                            count: {
                                fontSize: 18,
                                color: '#333',
                                fontWeight: 'bold',
                                lineHeight: 30,
                            }
                        },
                    }
                },
                // title: null,
                
                series: {
                    type: 'pie',
                    radius: [50, 70],
                    // top: '10%',
                    top: '2%',
                    height: '100%',
                    left: 'center',
                    // width: 400,
                    width: '100%',
                    itemStyle: {
                        borderColor: '#fff',
                        borderWidth: 1
                    },
                    label: {
                        show: false,
                        alignTo: 'none',
                        // formatter: `{name|{b}}\n{time|{c}条}`,
                        // formatter: `认领数:{c}\n认领率:{time|{d}条}`,
                        formatter: ({name, data, percent}) => {
                            return `{tips|${name}数:${data.value}\n占比:${percent}%}`;
                        },
                        minMargin: 5,
                        edgeDistance: 10,
                        lineHeight: 15,
                        rich: {
                            tips: {
                                fontSize: 13,
                                color: '#666'
                            }
                        }
                    },

                    // label: {
                    //     show: false,
                    //     position: 'center'
                    // },

                    labelLine: {
                        length: 15,
                        length2: 10,
                        maxSurfaceAngle: 80
                    },
                    emphasis: {
                        // scale: true,
                        // scaleSize: 2,
                        label: {
                            show: true,
                            // fontSize: '40',
                            // fontWeight: 'bold',
                        }
                    },
                    data: datas
                },
            };
            
            // const titles = ['认领数据', '推荐数据', '面试数据', '多推数据'];
            document.getElementsByClassName('chart').forEach((node, index) => {
                const nodeChart = echarts.init(node);
                if(index == 0) {
                    // const _total = this.getTotal('receiver');
                    // text: '{subtitle|人才总数}\n {count|5000}',
                    const _total = this.chartsData.res.candidateCount;
                    option.title.text = `{subtitle|人才总数}\n {count|${_total}}`;
                    option.series.data = this.chartsData.receiver;
                } else if(index == 1) {
                    // const _total = this.getTotal('recommender');
                    const _total = this.chartsData.res.receiverCount;
                    option.title.text = `{subtitle|认领数}\n {count|${_total}}`;
                    option.series.data = this.chartsData.recommender;
                } else if(index == 2) {
                    // const _total = this.getTotal('interview');
                    const _total = this.chartsData.res.recommendCount;
                    option.title.text = `{subtitle|推荐数}\n {count|${_total}}`;
                    option.series.data = this.chartsData.interview;
                } else if(index == 3) {
                    // const _total = this.getTotal('mutil');
                    const _total = this.chartsData.res.receiverCount;
                    option.title.text = `{subtitle|认领数}\n {count|${_total}}`;
                    option.series.data = this.chartsData.mutil;
                }
                nodeChart.setOption(option);
            });
        },
        getTotal(key) {
            let _total = 0;
            this.chartsData[key].forEach(item => {
                _total += item.value;
            });
            return _total;
        }
    }
}
</script>
<style lang="scss" scope>
.charts-container{
    padding: 20px 0;
    background: white;
    margin-top: 20px;
    border-radius: 6px;
    display: flex;
    flex-flow: row nowrap;
    // overflow: hidden;
    .chart-cell{
        flex-grow: 1;
        box-sizing: border-box;
        border-right: 1px dashed #ccc;
        // max-width: 300px;
        text-align: center;
        max-width: 25%;
        
        &:last-child{
            border-right: none;
        }
        .chart-title{
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
            text-align: center;
        }
        .chart{
            height: 202px;
        }
    }
}
</style>