<template>
    <div class="operation-data-table">
        <el-table
            border
            :data="tableData"
            tooltip-effect="light"
            style="width: 100%"
            v-loading="tableDataLoading"
            >
            <el-table-column
                prop="userName"
                label="顾问名称"
                :show-overflow-tooltip="true"
                width="180">
            </el-table-column>
            <el-table-column
                prop="receiverCount"
                label="认领数"
                width="180">
            </el-table-column>
            <el-table-column
                prop="inUseFollowCount"
                label="有效跟进数">
            </el-table-column>
            <el-table-column
                prop="noUseFollowCount"
                label="无效跟进数">
            </el-table-column>
            <el-table-column
                prop="candidateCount"
                label="接受推荐人才数">
            </el-table-column>
            <el-table-column
                prop="recommendAcceptedCount"
                label="接受人才推荐数">
            </el-table-column>
            <el-table-column
                prop="recommendInterviewCount"
                label="接受面试数">
            </el-table-column>
            <el-table-column
                prop="recommendOfferCount"
                label="职位offer数">
            </el-table-column>
            <!-- <el-table-column
                v-for="(item, index) in titleStore.slice(3)"
                :key="index"
                header-align="left"
                :label="tableTitleMap[item].label"
                :min-width="tableTitleMap[item].minWidth"
                :class-name="tableTitleMap[item].customClass"
                :show-overflow-tooltip="tableTitleMap[item].showOverflowTooltip"
                >
            </el-table-column> -->
        </el-table>
    </div>
</template>

<script>

export default {
    name: 'operation-data-table',
	components: {
	},
    props: {
        tableDataLoading: {
            type: Boolean,
            default: false,
        },
        tableData: {
            type: Array,
            default: () => [],
        },
    },
	data() {
		return {
            // 赛道，认领人
            // 顾问名称 认领数 有效跟进数  无效跟进数 接受推荐人才数 接受面试数 职位offer数
            tableData2: [
                {
                    "userName": "Ua",
                    "userId": "ae82026d-9da9-4dd7-8276-ad66d54eb828",
                    "receiverCount": 16,
                    "noUseFollowCount": 0,
                    "inUseFollowRecord": 0,
                    "candidateCount": 3,
                    "recommendAcceptedCount": 0,
                    "recommendInterviewCount": 0,
                    "recommendOfferCount": 0
                },
                {
                    "userName": "Ub",
                    "userId": "ae82026d-9da9-4dd7-8276-ad66d54eb828",
                    "receiverCount": 13,
                    "noUseFollowCount": 0,
                    "inUseFollowRecord": 0,
                    "candidateCount": 0,
                    "recommendAcceptedCount": 0,
                    "recommendInterviewCount": 0,
                    "recommendOfferCount": 0
                },
            ],
        };
    },
    created() {

    },
	mounted() {
		
	},
	methods: {}
}
</script>
<style lang="scss" scope>
.operation-data-table{
    flex-grow: 1;
    display: flex;
    display: -webkit-flex; /* Safari */
    flex-flow: column nowrap;

    letter-spacing: 0px;
    overflow: hidden;
    .el-table {
        overflow-y: auto;
        color: #666;
        &::before{
            display: none;
        }
        
        .el-table__row {
            td:nth-last-of-type(1) {
                border-right-color: #EBEEF5;
            }
        }
        th {
            padding: 0 0 0 20px;
            height: 42px;
            line-height: 42px;
            background-color: #E8E8E8;
            // border-color: #CCC;
            color: #666;
        }
        .el-table__row{
            >td{
                .cell{
                    padding-left: 30px;
                }
            }
        }
    }
}


</style>