<template>
    <div class="operation-platform-container">
        <top-wrap
            :total="page.total"
            :filterIndex="filterIndex"
            :filter-store="filterStore"
            :header-filter="headerFilter"
            :default-month="defaultMonth"
            :charts-data="chartsData"
            :loading-charts="loadingCharts"
            @top-data-change-cb="topDataChange"
            @clean-head-filter="handelCleanHeadFilter"
            >

            <!-- 饼图 -->
            <template v-slot="{chartsData, loadingCharts}">
                <operation-data-charts
                    :charts-data="chartsData"
                    :loading-charts="loadingCharts"
                />
            </template>
            
        </top-wrap>

        <operation-data-table
            :table-data="tableData"
            :table-data-loading="tableDataLoading"
        />
        <!-- :title-store="titleStore" -->

        <table-page-tab
            :filterIndex="filterIndex"
            :pageTabs="pageTabJson"
            :in-tab="false"
        />

        <div class="work-table-footer">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.current + 1"
                :page-sizes="[20, 30, 50]"
                :page-size="page.size"
                class="el-pagination-workTable"
                layout="total, sizes, prev, pager, next, slot"
                :total="page.total">
                <span class="pagination-text">
                    <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                    <span @click="handlePagerJump">跳转</span>
                </span>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

import TopWrap from './component/top-wrap.vue';
import TablePageTab from '#/component/workTable/table-page-tab.vue';
import OperationDataTable from './layout/operation-data-table.vue';
import OperationDataCharts from './component/operation-data-charts.vue';

import operationPlatformService from '#/js/service/operationPlatformService.js';

const lastMonth = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD HH:mm:ss");
const pre1Month = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD HH:mm:ss");

export default {
    // name: 'operation-data',
    name: 'OperationData',
	components: {
        TopWrap,
        TablePageTab,
        OperationDataTable,
        OperationDataCharts,
	},
	data() {
		return {
            page: {
                size: 20,
                current: 0,
                total: 0,
            },
            // allSelect: [],

            filterIndex: 1, // 0为平台运营，1为运营数据
            headerFilter: {
                receiverName: [],   // 认领人
                jobCategoryName: [],   // 赛道
            },
            filterStore: ['receiverName', 'jobCategoryName'],
            defaultMonth: [pre1Month ,lastMonth],

            tableData: [],
            tableDataLoading: false,

            chartsData: {
                receiver: [],
                recommender: [],
                interview: [],
                mutil: [],
            }, // 饼图数据


            pageTabJson: [
                    {
                        enTitle: "Operation Platform",
                        cnTitle: "平台运营",
                        path: "/operationPlatform",
                        title: "平台运营",
                        tabIndex: 0,
                        aliveComponent: 'OperationPlatform'
                    }, 
                    {
                        enTitle: "Operation Platform",
                        cnTitle: "平台运营",
                        path: "/operationPlatform/operationData",
                        title: "运营数据",
                        tabIndex: 1,
                        aliveComponent: 'OperationData'
                    }
                ],
            multipleCount: 0,
            pagerJump: 0,

            cacheArgs: null,
            loadingCharts: false,
        };
    },
    created() {
        this.getFilterData();
        this.getChartsData();
        this.requestListData();
    },
	mounted() {
		
	},
	methods: {
        // 处理缓存的筛选数据
        filterCacheData(originData, typeName) {
            return originData.map(item => {
                if(this.cacheArgs) {
                    const _isInclude = this.cacheArgs[typeName]?.includes(item.itemValue);
                    if(_isInclude) {
                        return {
                            ...item,
                            label: item.itemText,
                            isSelect:true,
                        };
                    } else {
                        return {
                            ...item,
                            label: item.itemText,
                        };
                    }
                } else {
                    return {
                        ...item,
                        label: item.itemText,
                    };
                }
            });
        },
        // 获取筛选数据
        getFilterData(args) {
            const params = {
                startDate: args?.startDate || pre1Month,
                endDate: args?.endDate || lastMonth,
                userId: args?.userId || '',

                receiverIds: args?.receiverIds || [],
                jobCategoryIds: args?.jobCategoryIds || [],
                businessTagIds: args?.businessTagIds || [],
            };
            // filterStore: ['receiverName', 'jobCategoryName', 'businessTags']

            operationPlatformService
            .getReportCategories(params)
            .then(res => {
                // const _jobCategory = res.map(item => ({
                //     ...item,
                //     label: item.itemText,
                // }));
                const _jobCategory = this.filterCacheData(res, 'jobCategoryIds');

                this.$set(this.headerFilter, 'jobCategoryName', _jobCategory);
            });
            

            operationPlatformService
            .getReportReceivers(params)
            .then(res => {
                const _receiver = this.filterCacheData(res, 'receiverIds');

                this.$set(this.headerFilter, 'receiverName', _receiver);
            });
        },

        // 获取饼图数据
        getChartsData(args) {

            const params = {
                startDate: args?.startDate || pre1Month,
                endDate: args?.endDate || lastMonth,
                userId: args?.userId || '',
                
                receiverIds: args?.receiverIds || [],
                jobCategoryIds: args?.jobCategoryIds || [],
            };

            this.loadingCharts = true;
            // 饼数据
            operationPlatformService
            .getMultiReport(params)
            .then(res => {
               
                const _receiver = [
                    { name: '认领', value: res.receiverCount || 0},
                    { name: '未认领', value: (res.candidateCount - res.receiverCount) >=0 ? (res.candidateCount - res.receiverCount): 0},
                ];
                const _recommender = [
                    { name: '推荐', value: res.recommendCount || 0},
                    { name: '未推荐', value: (res.receiverCount - res.recommendCount) >=0 ? (res.receiverCount - res.recommendCount): 0 },
                ];
                const _interview = [
                    { name: '面试', value: res.interviewCount || 0},
                    { name: '未面试', value: (res.recommendCount - res.interviewCount) >=0 ? (res.recommendCount - res.interviewCount): 0 },
                ];
                const _mutil = [
                    { name: '0次推荐', value: res.notRecommendCount || 0},
                    { name: '1次推荐', value: res.oneRecommendCount || 0},
                    { name: '2次推荐', value: res.twoRecommendCount || 0},
                    { name: '3次以上推荐', value: res.threeMoreRecommendCount || 0},
                ];
             
                this.$set(this.chartsData, 'receiver', _receiver);
                this.$set(this.chartsData, 'recommender', _recommender);
                this.$set(this.chartsData, 'interview', _interview);
                this.$set(this.chartsData, 'mutil', _mutil);
                this.$set(this.chartsData, 'res', res);
            })
            .finally(() => {
                this.loadingCharts = false;
            });
        },

        // 获取表格数据
        requestListData(args) {
            let params = {
                startDate: args?.startDate || pre1Month,
                endDate: args?.endDate || lastMonth,
                userId: args?.userId || '',
                start: (+this.page.current)*(+this.page.size),
                take: this.page.size,

                receiverIds: args?.receiverIds || [],
                jobCategoryIds: args?.jobCategoryIds || [],
            };


            this.tableDataLoading = true;
            
            operationPlatformService
            .getSnapshotDetailReport(params)
            .then(res => {
                this.tableData = res.list || [];
                
                this.page.total = res.total;
            })
            .finally(() => {
                this.tableDataLoading = false;
                this.scrollTop();
            });
        },

        handlePagerJump() {
            let currentPager = Number(this.pagerJump),
            currentPageTotal = Math.ceil(this.page.total/this.page.size);

            if(currentPager > 0 && currentPager <= currentPageTotal){
                this.handleCurrentChange(currentPager);
            }
        },
        handleSizeChange(val) {
            this.page.current = 0;
            this.page.size = val;
            this.requestListData(this.cacheArgs);
        },
        handleCurrentChange(val) {
            this.page.current = +val-1;
            this.requestListData(this.cacheArgs);
        },
        topDataChange(params, requestFilter = false) {
            // 缓存参数
            this.cacheArgs = params;

            if(requestFilter) {
                this.getFilterData(params);
            }

            this.page.current = 0;

            this.getChartsData(params);
            this.requestListData(params);
        },
        // 清理已选标签
        handelCleanHeadFilter (label){
            const list = this.headerFilter[label] || [];
            for (let key in list){
                list[key].isSelect = false;
            }
        },
        scrollTop() {
            setTimeout(() => {
                document.querySelector('.el-table__body-wrapper').scrollTo({ 
                    top: 0, 
                    behavior: "smooth" 
                });
            }, 50);
        },
    }
}
</script>
<style lang="scss" scope>
.color-orange{
    color: $orange;
}
.operation-platform-container{
    height: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    background: url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
}
</style>